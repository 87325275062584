<template>
  <div>
    <div class="filters-row df">
      <input
        id="table-filter"
        ref="filter"
        style="width: 200px"
        type="text"
        class="form-control"
        :placeholder="$t('filterTable')"
        @keyup="filterTable"
      />
    </div>
    <div class="tabe-responsive">
      <table
        ref="table"
        class="table-style table-settings table_sort"
      >
        <thead>
          <tr>
            <th>{{ $t('employeeTitle') }}</th>
            <th>{{ $t('desk') }}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <template v-for="(user, index) in fixedDeskUsers">
            <tr :key="index" :data-val="user.regularUser">
              <td :data-label="$t('employee') + ':'">
                <span v-if="user.context.firstName !== undefined"
                  >{{ user.context.firstName }}
                  {{ user.context.lastName }}</span
                ><span v-else> {{ user.regularUser }}</span>
              </td>
              <td>
                {{ user.desk }}
              </td>
              <td data-label>
                <router-link
                  class="btn btn-secondary"
                  :to="{
                    name: 'EditAbsencesPage',
                    params: {
                      username: user.regularUser
                    }
                  }"
                >
                  {{ $t('editAbsences') }}
                </router-link>
              </td>
            </tr>
          </template>
          <div v-if="!fixedDeskUsers || !fixedDeskUsers.length">
            <tr>
              <td>
                <strong>{{ $t('noRecordsFound') }}</strong>
              </td>
            </tr>
          </div>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import OkkuApi from '@/services/OkkuApi'
import { mapState } from 'vuex'
import { filterTableMixin } from '@/mixins/mixins'

export default {
  name: 'FixedDeskEmployeesTable',
  mixins: [filterTableMixin],
  data() {
    return {
      fixedDeskUsers: []
    }
  },
  computed: {
    ...mapState('common', {
      organisation: '$organisation'
    })
  },
  watch: {
    organisation() {
      this.getFixedDeskUsers()
      this.filterTable()
    }
  },

  mounted() {
    if (this.organisation) {
      this.getFixedDeskUsers()
    }
  },
  methods: {
    getFixedDeskUsers() {
      OkkuApi.getFixedDeskUsers().then(fixedDeskUsers => {
        this.fixedDeskUsers = fixedDeskUsers
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tip-icon::v-deep {
  .fa-question-circle {
    font-size: 16px;
    margin-right: 5px;
    cursor: pointer;
  }
}
table {
  input {
    display: inline-block;
    vertical-align: middle;
  }
}
td {
  text-align: left;
}
</style>
